import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { s } from '../clever-ui-kit/style'

// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { IntroPage } from '../clever-ui-kit/intros'
import { WrapperContainer, WrapperSection } from '../clever-ui-kit/wrappers'

export default function CountryTempplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const theme = state?.theme ? state.theme : initialState.theme

  const country = data.thisCountry?.frontmatter
  const countryHtml = data.thisCountry?.html

  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (country.language)
      dispatch({ type: 'SET_LANGUAGE', payload: country.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={country.seo_title || country.name}
        description={country.seo_desc || `Transport ${country.name}`}
      />

      <IntroPage
        title={country.name}
        subtitle={country.origin_name}
        descriptionList={country.descriptionList}
        theme={theme}
      />
      <WrapperSection extraCss={sWrapperSection}>
        <WrapperContainer theme={theme}>
          <div dangerouslySetInnerHTML={{ __html: countryHtml }} />
        </WrapperContainer>
      </WrapperSection>
    </>
  )
}

const sWrapperSection = {
  [s.sm_down]: { marginTop: 0, paddingTop: 0 },
  [s.md]: { marginTop: 0, paddingTop: 0 },
  [s.lg]: { marginTop: 0, paddingTop: 0 },
}

export const query = graphql`
  query($language: String!, $shortcut: String!) {
    thisCountry: markdownRemark(
      fileAbsolutePath: { regex: "/countries/" }
      frontmatter: { language: { eq: $language }, shortcut: { eq: $shortcut } }
    ) {
      html
      frontmatter {
        language
        name
        shortcut
        origin_name
        seo_title
        seo_desc
        descriptionList {
          text
        }
        featuresList {
          # icon {
          #   publicURL
          #   name
          # }
          text
          value
          unit
        }
        # map {
        #   publicURL
        # }
      }
    }
  }
`
